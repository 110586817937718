<template>
  <div class="client" :class="{ 'menu-active': menuOpened }">
    <SystemNotifications />
    <SideBarMenu />
    <MobileHeader />
    <div class="client__content">
      <div class="client__content__header">
        <HeaderMenu :bg-color="`${colorsHtml.navy}`" />
      </div>
      <router-view name="client" />
      <router-view
        name="account_contract_sign"
        :route-back="{ name: 'account' }"
      />
      <Footer />
    </div>
    <CookiesFooter v-if="false" />
  </div>
</template>

<script>
import SideBarMenu from "@/components/Menu/SideBarMenu";
import HeaderMenu from "@/components/Menu/HeaderMenu";
import MobileHeader from "@/components/Header/MobileHeader";
import CookiesFooter from "@/components/Footer/CookiesFooter";
import Footer from "@/components/Footer/Footer";
import SystemNotifications from "@/components/Helpers/SystemNotifications";

import { mapState } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";
import { customUser2 } from "@/assets/icons";
import colorsHtml from "/colors.config.json";
library.add(customUser2);

export default {
  name: "Client",
  components: {
    SideBarMenu,
    HeaderMenu,
    MobileHeader,
    CookiesFooter,
    Footer,
    SystemNotifications,
  },
  computed: {
    ...mapState("helper", {
      menuOpened: "menuOpened",
    }),
  },
  data() {
    return {
      colorsHtml: colorsHtml,
    };
  },
};
</script>

<style lang="scss" scoped>
.client {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: flex;
  flex-flow: row no-wrap;
  max-width: 1920px;
  margin: 0 auto;

  &__content {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;

    &__header {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1000;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
    }

    @media (min-width: 768px) {
      max-width: calc(100% - 50px);
    }

    @media (min-width: 1200px) {
      max-width: calc(100% - 310px);
    }

    @media (min-width: 1600px) {
      max-width: calc(100% - 340px);
    }
  }
}
</style>
